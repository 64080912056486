import React from 'react';
import './FirstSection.css';

const FirstSection = () => {
  return (
    <section className="first-section">
      <div className="content">
        <div className="image">
          <div className='image-container'/>
        </div>
        <div className="text">
          <h2>KITTO</h2>
          <p>Are you tired of regular tokens? <br></br><br></br> Introducing Kitto - the Pepe's cat
            It's not just a cat, it's a CATastrophe of hilarity in the blockchain world!</p>
        </div>
      </div>
    </section>
  );
};

export default FirstSection;
